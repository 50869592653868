<template>
  <div class="main__wrapper">
    <div class="contacts__content">
      <div class="contacts__info">
        <h2 class="title-h4 contacts__title">{{ $t('main.info') }}</h2>
        <div class="contacts_block">
          <p class="contacts__text">
            <a
              href="https://www.google.com/maps?ll=50.448104,30.52169&z=16&t=m&hl=en&gl=UA&mapclient=embed&q=Prorizna+St,+2+Kyiv+02000"
              target="_blank"
              class="contacts__link"
            >
              {{ $t(`contact_info.address`) }}
            </a>
          </p>
        </div>
        <div class="contacts_block">
          <p class="contacts__text">{{ $t(`contact_info.tel`) }}</p>
          <a href="tel:+380442780808" class="contacts__link">(044) 278-08-08</a>
        </div>
        <div class="contacts_block">
          <p class="contacts__text">{{ $t(`contact_info.mailto`) }}</p>
          <a href="mailto:dart@tourism.gov.ua" class="contacts__link">
            dart@tourism.gov.ua
          </a>
        </div>
        <div class="contacts_block">
          <p class="contacts__text">{{ $t(`contact_info.zip_code`) }}</p>
        </div>
        <div class="contacts__map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2540.6239989938235!2d30.52169039999999!3d50.4481039!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ce56d364c989%3A0x218b5ccfe12acf80!2sProrizna%20St%2C%202%2C%20Kyiv%2C%2002000!5e0!3m2!1sen!2sua!4v1663601212486!5m2!1sen!2sua"
            width="100%"
            height="316"
            style="border: 0"
            allowfullscreen=""
            referrerpolicy="no-referrer-when-downgrade"
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <div class="contacts__form">
        <SelectField
          v-model="selectValue"
          :items="options"
          :placeholder="$t('main.theme')"
        />
        <text-field
          id="key-password"
          v-model="textValue"
          type="name"
          class="contacts__text-field"
          :placeholder="$t('main.name_sirname')"
        />
        <div class="modal__key-file">
          <FileUpload
            v-model="file"
            :is-multiple="false"
            :valid-formats="['doc', 'docx', 'pdf', 'img', 'jpeg', 'jpg', 'png']"
            isFileFormat
            :max-size-k-b="25000"
          />
          <!-- <label for="key-file" class="modal__key-file-label">
            <span class="modal__key-file-caption">
              <svg-icon name="file" />
              Перетягніть сюди файл або оберіть
            </span>
          </label>
          <input
            id="key-file"
            ref="key_file"
            class="modal__key-file-input"
            type="file"
            name="key-file"
          /> -->
        </div>
        <p class="contacts__comment">{{ $t('main.message') }}</p>
        <textarea type="text" class="contacts__comment_input" />
        <div class="contacts__comment_btn" @click="goToHome">
          <Button>{{ $t('modals.send_key') }}</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectField from '@/elements/SelectField.vue';
import TextField from '@/elements/TextField.vue';
import FileUpload from '@/elements/FileUpload.vue';

export default {
  components: { SelectField, TextField, FileUpload },
  data() {
    return {
      selectValue: '',
      textValue: '',
      file: null,
      options: ['Ліцензії', 'Реєстрація СТД', 'Інше'],
      contactInfo: [
        {
          title: 'address',
          value:
            'https://www.google.com/maps?ll=50.448104,30.52169&z=16&t=m&hl=en&gl=UA&mapclient=embed&q=Prorizna+St,+2+Kyiv+02000',
        },
        {
          title: 'tel',
          value: '(044) 278-08-08',
        },
        {
          title: 'mailto',
          value: 'dart@tourism.gov.ua',
        },
        {
          title: 'zip_code',
          value: '01001',
        },
      ],
    };
  },
  methods: {
    goToHome() {
      this.$router.push({ name: 'landing-page' });
    },
  },
};
</script>

<style lang="sass" scoped>
.contacts__content
  display: flex
  justify-content: space-between
  gap: 4rem
  @include s
    flex-direction: column

.contacts_block
  display: flex
  gap: 10px

.contacts__title
  margin-bottom: 24px

.contacts__text
  margin-bottom: 2.4rem

.contacts__link
  color: $black

.contacts__link:hover
  text-decoration: underline

.contacts__form
  border: 2px solid $gray_border
  padding: 1rem 3rem
  min-width: 400px
  @include xs
    padding: 1rem 2rem
    min-width: 100%

.contacts__info
  flex-grow: 1

.contacts__map
  margin-top: 10rem
  width: 100%
  @include s
    margin-top: 3rem

.contacts__text-field
  margin-top: 4rem

.modal__key-file
  @include s
    max-width: 400px
  @include xs
    max-width: 100%

.contacts__cart
  width: 790px
  margin-top: 6rem

.icon-fale
  height: 18px
  width: 20px

.contacts__inputs
  margin-bottom: 20px

.autocomplete-box
  min-width: 400px
  margin-bottom: 40px

.contacts__comment
  margin-top: 40px
  color: $gray

.contacts__comment_input
  border: 2px solid $gray
  width: 100%
  height: 200px
  margin-top: 10px
  padding: 10px 20px

.contacts__comment_btn
  margin-top: 20px
  display: flex
  justify-content: center
</style>
